
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V4ZM4.33211 6.33211C4.09745 6.56676 4 6.82523 4 7V17C4 17.1748 4.09745 17.4332 4.33211 17.6679C4.56676 17.9025 4.82523 18 5 18H8C8.55228 18 9 18.4477 9 19C9 19.5523 8.55228 20 8 20H5C4.17477 20 3.43324 19.5975 2.91789 19.0821C2.40255 18.5668 2 17.8252 2 17V7C2 6.17477 2.40255 5.43324 2.91789 4.91789C3.43324 4.40255 4.17477 4 5 4H8C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6H5C4.82523 6 4.56676 6.09745 4.33211 6.33211ZM15 5C15 4.44772 15.4477 4 16 4H19C19.8252 4 20.5668 4.40255 21.0821 4.91789C21.5975 5.43324 22 6.17477 22 7V17C22 17.8252 21.5975 18.5668 21.0821 19.0821C20.5668 19.5975 19.8252 20 19 20H16C15.4477 20 15 19.5523 15 19C15 18.4477 15.4477 18 16 18H19C19.1748 18 19.4332 17.9025 19.6679 17.6679C19.9025 17.4332 20 17.1748 20 17V7C20 6.82523 19.9025 6.56676 19.6679 6.33211C19.4332 6.09745 19.1748 6 19 6H16C15.4477 6 15 5.55228 15 5Z" fill="black"/>
</svg>

  </template>

  <script>
  export default {
    name: 'OutsideOp',
    inheritAttrs: true,
  }
  </script>
